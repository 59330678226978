<template>
  <div class="card">
    <el-input size="mini" style="width: 25%" v-model="lineSize[index]['Length']" :placeholder="$t('line.length')"></el-input>
    <el-input size="mini" style="width: 25%" v-model="lineSize[index]['Width']" :placeholder="$t('line.width')"></el-input>
    <el-input size="mini" style="width: 25%" v-model="lineSize[index]['Height']" :placeholder="$t('line.height')"></el-input>
    <el-input size="mini" style="width: 25%" v-model="lineSize[index]['Quantity']" :placeholder="$t('line.quantity')"></el-input>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  props: {
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters(['lineSize'])
  },
  data() {
    return {
    }
  },
  methods: {
  }
}

</script>

<style scoped lang="scss">
.card {
  margin-bottom: 1rem;
}
</style>
